<fc-grid [columns]="columns" [data]="documents" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary (click)="export(row)" [disabled]="!row.signatureDate">
            Export
        </fc-button-secondary>
    </div>
</ng-template>

<ng-template #statusTemplate let-row>
    @if (row.signatureDate) {
        <span>Signed on {{localDate(row.signatureDate)}}</span>
    }
    @else {
        <span class="text-error-500">Not yet signed</span>
    }
</ng-template>
