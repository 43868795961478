<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
        {{ label }}
    </label>
    <div class="relative">
        <input type="text"
            class="w-full border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 py-[12px] rounded-lg hover:bg-gray-50"
            [placeholder]="placeholder"
            matInput
            [formControl]="formControl"
            [matAutocomplete]="auto"/>

        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" [displayWith]="displayWith">
            <mat-option *ngFor="let item of filteredOptions | async" [value]="item">
                {{ displayWith(item) }}
            </mat-option>
        </mat-autocomplete>
    </div>
</div>