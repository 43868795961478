<div class="fixed flex flex-col z-40 top-0 left-0 w-[14.5rem] h-full border-r bg-white select-none gap-6">
    <div class="pt-8">
        <div class="flex flex-none justify-center items-center gap-3">
            <div class="flex items-center gap-2 font-inter font-medium text-xl text-primary-700">
                <img class="w-8 h-8" src="/assets/images/logo.svg"/> FightCloud
            </div>
            <div class="p-1 text-gray-500 cursor-pointer" (click)="collapseClick.emit()">
                <bars-3-outline-icon class="heroicon-sw-1.5" [size]="20" />
            </div>
        </div>
    </div>
    <div class="grow text-gray-700">
        <div class="flex flex-col justify-between h-full">
            <div class="mx-4">
                <a routerLink="/" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <span>Calendar</span>
                    </div>
                </a>
                <a routerLink="/session/create" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <span>Add Session</span>
                    </div>
                </a>
                <a routerLink="/student" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center hover:bg-primary-50 hover:text-primary-700">
                    <div class="font-medium flex items-center gap-4">
                        <span>Students</span>
                    </div>
                </a>
            </div>
            <div>
                <!-- Applications Section -->
                <div class="mx-4">
                    <div class="relative flex justify-between items-center cursor-pointer px-3.5 py-2 mx-1 my-1 rounded-lg font-medium hover:bg-primary-50 hover:text-primary-700"
                        (click)="isAppsExpanded = !isAppsExpanded">
                        <span>Applications</span>
                        <chevron-down-outline-icon *ngIf="!isAppsExpanded" [size]="16"/>
                        <chevron-up-outline-icon *ngIf="isAppsExpanded" [size]="16"/>
                    </div>

                    <div *ngIf="isAppsExpanded" class="flex flex-col pl-4">
                        <a [href]="checkinUrl" target="_blank"
                            class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-medium items-center hover:bg-primary-50 hover:text-primary-700">
                            <span>Attendance</span>
                        </a>
                        <a [href]="studentPortalUrl" target="_blank"
                            class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-medium items-center hover:bg-primary-50 hover:text-primary-700">
                            <span>Portal</span>
                        </a>
                        <a [href]="kioskUrl" target="_blank"
                            class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-medium items-center hover:bg-primary-50 hover:text-primary-700">
                            <span>Kiosk</span>
                        </a>
                    </div>
                </div>
                <div class="pb-5 border-b mx-4">
                    <a routerLink="/settings" routerLinkActive="bg-primary-50 text-primary-700"
                    class="relative flex rounded-lg px-3.5 py-2 mx-1 my-1 font-normal text-base items-center hover:bg-primary-50 hover:text-primary-700">
                        <div class="font-medium flex items-center gap-4">
                            <span>Settings</span>
                        </div>
                    </a>
                </div>
                <div *ngIf="user$ | async; let user">
                    <div class="flex my-6">
                        <button class="font-medium text-gray-900 grow border rounded-lg py-2 text-center mx-4">{{user.gymName}}</button>
                    </div>
                    <div class="flex gap-3 pt-6 mx-4 mb-8 justify-between border-t">
                        <div class="flex gap-3 items-center">
                            <app-avatar [imageUrl]="user.imageUrl" initials="{{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}"></app-avatar>
                            <div class="min-w-0 text-sm font-medium flex flex-col">
                                <span>{{user.firstName}} {{user.lastName}}</span>
                            </div>
                        </div>
                        <div class="w-8 h-8 mb-2 cursor-pointer flex justify-center" [matMenuTriggerFor]="profileMenu">
                            <ellipsis-vertical-outline-icon [size]="20" class="text-gray-500 font-medium"/>
                        </div>
                        <mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
                            <button mat-menu-item (click)="logout()">
                                <span>Logout</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>