import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'fc-timeline',
  templateUrl: './timeline.component.html',
})
export class TimelineComponent {
  @Input() timelineData: { key: string, values: any[] }[] = [];
  @Input() iconTemplate?: TemplateRef<any>;
  @Output() actionTriggered = new EventEmitter<any>();

  handleItemAction(item: any) {
    this.actionTriggered.emit(item);
  }
}