<div class="flex flex-col gap-3">
    <div *ngFor="let month of timelineData">
        <!-- Month Header -->
        <div class="w-full h-6 flex items-center gap-2 mb-3">
            <div class="flex-grow border-t bg-gray-200"></div>
            <div class="text-center text-gray-500 text-base font-bold font-['Inter'] leading-normal">
                {{ month.key }}
            </div>
            <div class="flex-grow border-t bg-gray-200"></div>
        </div>

        <!-- Timeline Items for this Month -->
        <div class="relative flex flex-col">
            <fc-timeline-item
                *ngFor="let item of month.values; let last = last;"
                [item]="item"
                [isLast]="last"
                [iconTemplate]="iconTemplate"
                (actionTriggered)="handleItemAction($event)">
            </fc-timeline-item>
        </div>
    </div>
</div>
