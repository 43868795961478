@if (loading) {
    <fc-loader/>
}
@else {
    <div class="w-full mt-4" *ngIf="studentMembership && studentMembership.id">
        <div class="flex mb-4 gap-2 justify-between">
            <div class="flex gap-2 items-center">
                <h1 class="text-xl">{{studentMembership.name}}</h1>
                <div *ngIf="studentMembership.status == 1" class="py-1 px-2 bg-gray-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-gray-700 text-[12px] font-medium leading-none">Pending</div>
                </div>
                <div *ngIf="studentMembership.status == 2" class="py-1 px-2 bg-success-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-success-700 text-[12px] font-medium leading-none">Active</div>
                </div>
                <div *ngIf="studentMembership.status == 3" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-error-700 text-[12px] font-medium leading-none">Inactive</div>
                </div>
                <div *ngIf="studentMembership.status == 4 && studentMembership.cancellationDate" class="py-1 px-2 bg-error-100 rounded-2xl justify-center items-center gap-[6px] flex">
                    <div class="text-center text-error-700 text-[12px] font-medium leading-none">Cancelling: {{studentMembership.cancellationDate | date:'dd-MM-yyyy'}}</div>
                </div>
            </div>
                <button (click)="cancelMembership()" *ngIf="studentMembership.status == 2 && !cancelled" type="button"
                    class="h-12 sm:h-[38px] flex items-center gap-2 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2">
                    <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> 
                    <span class="block sm:hidden">Cancel</span>
                    <span class="hidden sm:block">Cancel Membership</span>
                </button>
                <button (click)="uncancelMembership()" *ngIf="studentMembership.status == 4 && !cancelled" type="button"
                    class="h-12 sm:h-[38px] flex items-center gap-2 text-warning-700 border border-warning-700 bg-warning-25 hover:bg-warning-100 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-4 py-2">
                    <trash-outline-icon svgClass="flex-inline" [size]="16"></trash-outline-icon> 
                    <span class="block sm:hidden">Reactivate</span>
                    <span class="hidden sm:block">Reactivate Membership</span>
                </button>
        </div>
    </div>
    <div class="mt-1">
        <fc-timeline
            [timelineData]="history"
            (actionTriggered)="handleViewDetails($event)">
        </fc-timeline>
    </div>  
}
