  <form [formGroup]="form" (ngSubmit)="confirmDialog()">
  <h1 class="select-none text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
  Add membership
  </h1>
  <div class="pb-4 text-sm text-gray-500 px-6">
      <div *ngIf="data.memberships && data.memberships.length > 0">
        <label class="text-sm font-medium text-gray-700 mb-2">Membership</label>
        <mat-select formControlName="membership" [hideSingleSelectionIndicator]="true"
          class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm pr-4 py-[10px] rounded-lg w-full hover:bg-gray-50 mt-2">
          <mat-select-trigger>
            <span class="pl-4">
              {{form.get('membership')?.value?.name}}
            </span>
          </mat-select-trigger>
          <mat-option *ngFor="let membership of data?.memberships" [value]="membership"  class="-ml-2">{{membership.name}}</mat-option>
        </mat-select>
      </div>
      <div class="flex flex-col gap-1.5 mt-4">
        <label class="text-sm font-medium text-gray-700">Start date</label>
        <div class="relative">
          <input formControlName="startDate" [min]="today" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"  
          [ngClass]="{'text-gray-900 w-full text-sm px-4 py-3 rounded-lg border focus:outline-0 focus:ring hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !form.get('startDate')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || form.get('startDate')?.valid ?? true}"/>
          <calendar-outline-icon class="absolute right-3 top-2.5 w-6 text-gray-500 pointer-events-none"></calendar-outline-icon>
          <mat-datepicker touchUi #date></mat-datepicker>
        </div>
        <span *ngIf="submitted && !form.get('startDate')?.valid ?? false" class="text-error-500">Start date is required</span>
      </div>
  </div>
  <div class="flex pt-4 pb-6 px-6 gap-3">
    <button
      type="button"
      class="h-[38px] grow text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center"
      (click)="closeDialog()">
      Cancel
    </button>
    <button
      type="submit"
      class="h-[38px] grow bg-primary-600 text-white border border-primary-300 hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center">
      Add membership
    </button>
  </div>
</form>