import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UploadImageModalComponent } from '../../modals/upload-image-modal/upload-image-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-user-modal',

  templateUrl: './add-user-modal.component.html',
  styleUrl: './add-user-modal.component.scss'
})
export class AddUserModalComponent {
  form: FormGroup;
  imageUrl: string | undefined;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<AddUserModalComponent>, private fb: FormBuilder, private dialog: MatDialog) { 
    this.form = this.fb.group({
      firstName: new FormControl<string|null>(null, Validators.required),
      lastName: new FormControl<string|null>(null, Validators.required),
      email: new FormControl<string|null>(null, [Validators.required, Validators.email]),
      mobile: new FormControl<string|null>(null, Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)),
      imageUrl: new FormControl<string|null>(null)
    });
  }

  closeDialog(){
    this.dialogRef.close(null);
  }

  confirmDialog(){
    this.submitted = true;
    if (this.form.valid) {
      const formData = this.form.value;
      this.dialogRef.close(formData)
    }
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadImageModalComponent, { width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.get('imageUrl')?.setValue(`${environment.blobBaseUrl}/avatar/${result}`);
        this.imageUrl = `${environment.blobBaseUrl}/avatar/${result}`;
      }
    });
  }
}
