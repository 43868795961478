<div *ngIf="isAuthenticated$ | async">
  <div class="hidden sm:flex">
    <app-side-navigation *ngIf="!collapsed && !isMobile" (collapseClick)="collapse(true)"></app-side-navigation>
    <app-side-navigation-minimised *ngIf="collapsed || isMobile" (collapseClick)="collapse(false)" [isMobile]="isMobile"></app-side-navigation-minimised>
  </div>
  <div class="block sm:hidden">
    <app-mobile-navigation></app-mobile-navigation>
  </div>
  <div [ngClass]="{ 'ml-0': true, 'sm:ml-[14.5rem]': !collapsed && !isMobile, 'sm:ml-16': collapsed || isMobile}" class="bg-white px-4 sm:px-8 pt-4 pb-8">
    <router-outlet></router-outlet>
  </div>
</div>