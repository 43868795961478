import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadImageModalComponent } from 'src/app/components/modals/upload-image-modal/upload-image-modal.component';
import { StudentService } from 'src/app/services/student.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ToasterType } from 'src/app/models/toaster.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { DisciplineGrouped } from 'src/app/models/discipline.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { MembershipService } from 'src/app/services/membership.service';
import { forkJoin } from 'rxjs';
import { Membership } from 'src/app/models/membership.model';
import { StudentTrainingCreateModalComponent } from 'src/app/components/modals/student-training-create-modal/student-training-create-modal.component';
import { StudentMembershipCreateModalComponent } from 'src/app/components/modals/student-membership-create-modal/student-membership-create-modal.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { getDateString } from 'src/app/lib/date-helpers';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';

interface DisciplineGrade {
  disciplineId: number;
  disciplineName: string;
  gradeId: number;
  gradeName: string;
  manageRank: boolean;
  gradeDate: Date;
}

@Component({
  selector: 'app-student-create-form',
  templateUrl: './student-create-form.component.html',
  styleUrls: ['./student-create-form.component.scss']
})
export class StudentCreateFormComponent implements OnInit{
  @Input() student: any;
  disciplines: DisciplineGrouped[] = [];
  disciplinesAvailable: DisciplineGrouped[] = [];
  selectedDisciplines: DisciplineGrade[] = [];
  memberships: Membership[] = [];
  membershipsAvailable: Membership[] = [];
  selectedMembershipIds: number[] = [];

  copyingText = false;

  paymentFrequencies = [
    {id: 1, name: 'Weekly'},
    {id: 2, name: 'Fortnightly'},
    {id: 3, name: 'Monthly'},
    {id: 4, name: '3-Monthly'},
    {id: 5, name: '6-Monthly'},
    {id: 6, name: 'Yearly'}
  ];

  imageUrl: string | undefined = undefined;

  editing = false;
  submitted = false;
  submitting = false;
  studentCreateForm = this.fb.group({
    firstName: new FormControl<string>('', Validators.required),
    lastName: new FormControl<string>('', Validators.required),
    dateOfBirth: new FormControl<string|null>(null),
    email: new FormControl<string|null>('', Validators.email),
    mobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)]),
    address: new FormControl<string|null>(null),
    imageUrl: new FormControl<string|null>(null),
    emergencyContact: this.fb.group({
      emergencyFirstName: new FormControl<string|null>(null),
      emergencyLastName: new FormControl<string|null>(null),
      emergencyRelationship: new FormControl<string|null>(null),
      emergencyMobile: new FormControl<string|null>(null, [Validators.pattern(/^(\+\d{2} \d{3} \d{3} \d{3}|\d{10}|\d{4} \d{3} \d{3}|\+\d{11})$/)])
    }),
    emergencyInformation: new FormControl<string|null>(null),
    memberships: this.fb.array([]),
    grades: new FormControl<{gradeId: number, manageGrade: boolean, gradeDate: Date}[]>([])
  })

  get studentName(): string | null {
    return this.studentCreateForm.get('firstName')?.value ?? null;
  }

  get hasEmail() {
    const emailControl = this.studentCreateForm.get('email');

    if (!emailControl) {
      return false;
    }

    return emailControl.value !== '' && emailControl.valid;
  }

  constructor(private fb: FormBuilder, private studentService: StudentService, private dialog: MatDialog, private ref: ChangeDetectorRef, private router: Router, private toasterService: ToasterService, private disciplineService: DisciplineService, private membershipService: MembershipService) { }

  ngOnInit(): void {
    forkJoin({
      disciplines: this.disciplineService.getDisciplineGrades(),
      memberships: this.membershipService.getMemberships()
    }).subscribe(result => {
      this.disciplines = result.disciplines;
      this.disciplinesAvailable = this.disciplines;
      this.memberships = result.memberships.filter(x => !x.isArchived);
      this.membershipsAvailable = this.memberships;
    })

    if (this.student) {
      let date: string | null = null;

      if (this.student.dateOfBirth) {
        const studentDob = new Date(this.student.dateOfBirth)
        const day = studentDob.getDate().toString().padStart(2, '0');
        const month = (studentDob.getMonth() + 1).toString().padStart(2, '0');
        const year = studentDob.getFullYear().toString();
        date = `${year}-${month}-${day}`;
      }

      this.editing = true;
      this.studentCreateForm.setValue({
        firstName: this.student.firstName || '',
        lastName: this.student.lastName || '',
        dateOfBirth: date,
        email: this.student.email|| '',
        mobile: this.student.mobile || '',
        address: this.student.address || '',
        imageUrl: this.student.imageUrl || '',
        emergencyContact: {
          emergencyFirstName: this.student.emergencyFirstName || '',
          emergencyLastName: this.student.emergencyLastName || '',
          emergencyRelationship: this.student.emergencyRelationship || '',
          emergencyMobile: this.student.emergencyMobile || ''
        },
        emergencyInformation: this.student.emergencyInformation || '',
        memberships: [],
        grades: []
      });
      
      if (this.student.imageUrl) {
        this.imageUrl = this.student.imageUrl;
      }
    }
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.studentCreateForm.valid) {
      this.submitting = true;
      if (this.student) {
        this.studentService.updateStudent(this.student.id, this.studentCreateForm.value).subscribe({
          next: () => {
            this.toasterService.addToaster({type: ToasterType.Success, message: 'Student updated'});
            this.studentCreateForm.markAsPristine();
            this.submitted = false;
            this.submitting = false;
          },
          error: (error) => {
            const emailControl = this.studentCreateForm.get('email');
            emailControl?.setErrors({ ...(emailControl.errors || {}), 'duplicate': error.error.message});
            this.submitting = false;
          }
        })
      } else {
        this.studentCreateForm.get('grades')?.setValue(this.selectedDisciplines.map((x) => { return { gradeId: x.gradeId, manageGrade: x.manageRank, gradeDate: x.gradeDate }}));

        this.studentService.createStudent(this.studentCreateForm.value).subscribe({
          next: (result) => {
            // this.toasterService.addToaster({type: ToasterType.Success, message: 'Student created'});
            if (this.hasEmail) {
              const dialogRef = this.dialog.open(ConfirmModalComponent, {
                width: '550px',
                data: { title: '🎉 Congratulations on another student! 🎉', content: `${this.studentName} has been sent a welcome email. Any membership invitations will be sent via a separate email and expire after 24 hours.`, yesText: 'Close' }
              });
          
              dialogRef.afterClosed().subscribe(() => {
                this.router.navigate(['student', result]);
              });
            } else {
              this.router.navigate(['student', result]);
            }           
            this.submitting = false;
          },
          error: (error) => {
            const emailControl = this.studentCreateForm.get('email');
            emailControl?.setErrors({ ...(emailControl.errors || {}), 'duplicate': error.error.message});
            this.submitting = false;
          }
        });
      }
    }
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadImageModalComponent, { data: { studentName: this.studentName }, width: '400px' });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.studentCreateForm.get('imageUrl')?.setValue(`${environment.blobBaseUrl}/avatar/${result}`);
        this.imageUrl = `${environment.blobBaseUrl}/avatar/${result}`;
        this.studentCreateForm.get('imageUrl')?.markAsDirty();
        this.ref.detectChanges();
      }
    });
  }

  addTrainingHistory() {
    const dialogRef = this.dialog.open(StudentTrainingCreateModalComponent, {
        width: '400px',
        data: { disciplines: this.disciplinesAvailable }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectedDisciplines.push({
            disciplineId: result.discipline.id,
            disciplineName: result.discipline.name,
            gradeId: result.grade.gradeId,
            gradeName: result.grade.gradeName,
            manageRank: result.manageGrade,
            gradeDate: result.gradeDate
          });

          this.disciplinesAvailable = this.disciplinesAvailable.filter(x => !this.selectedDisciplines.some(y => y.disciplineId == x.id));
        }
      });
  }

  removeTrainingHistory(disciplineId: number) {
    this.selectedDisciplines = this.selectedDisciplines.filter(x => x.disciplineId !== disciplineId);
    this.disciplinesAvailable = this.disciplines.filter(x => !this.selectedDisciplines.some(y => y.disciplineId == x.id));
  }

  addMembership() {
    const dialogRef = this.dialog.open(StudentMembershipCreateModalComponent, {
        width: '400px',
        data: {memberships: this.membershipsAvailable}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const membershipControl = this.studentCreateForm.get('memberships') as FormArray;
        membershipControl.push(new FormGroup({
          id: new FormControl<number>(result.membership.id),
          startDate: new FormControl<Date>(result.startDate)
        }));
        this.selectedMembershipIds.push(result.membership.id);
        this.membershipsAvailable = this.memberships.filter(x => !this.selectedMembershipIds.some(y => y == x.id));
      }
    });
  }

  removeMembership(membershipId: number) {
    const membershipControl = this.studentCreateForm.get('memberships') as FormArray;

    for (let i = 0; i <= membershipControl.controls.length; i++) {
      if (membershipControl.controls[i].get('id')?.value === membershipId) {
        membershipControl.removeAt(i);
      }
    }

    console.log(this.studentCreateForm);

    this.selectedMembershipIds = this.selectedMembershipIds.filter(x => x != membershipId);
    this.membershipsAvailable = this.memberships.filter(x => !this.selectedMembershipIds.some(y => y == x.id));
  }

  get selectedMemberships() {
    return this.memberships.filter(x => this.selectedMembershipIds.some(y => y == x.id));
  }

  getStartDate(membershipId: number) {
    const membershipsControl = this.studentCreateForm.get('memberships') as FormArray;

    return membershipsControl.controls.filter(x => x.get('id')?.value === membershipId)[0].get('startDate')?.value;
  }

  getPaymentFrequency(id: number) {
    return this.paymentFrequencies.find(x => x.id === id)?.name;
  }

  getTotalCharged(amount: number, service: number, chargeFees: boolean) {
    if (!amount) {
      return 0;
    }

    if (!service) {
      return amount;
    }

    if (chargeFees) {
      return amount + service;
    }

    return amount;
  }

  copyText() {
    this.copyingText = true;
    navigator.clipboard.writeText(environment.studentPortalUrl);

    setTimeout(() => {
      this.copyingText = false;
    }, 2000)
  }

  dateChange(date: MatDatepickerInputEvent<Date>) {
      if (date?.value) {
        this.studentCreateForm.get('dateOfBirth')?.setValue(getDateString(date.value));
        this.studentCreateForm.markAsDirty();
      }
  }
}
