@if (loading) {
    <fc-loader/>
}
@else if (activeMemberships.length == 0 && inactiveMemberships.length == 0) {
    @if (memberships.length == 0) {
        <fc-empty-grid heading="No memberships configured">
            <p description>Create a membership in settings to get started.</p>
            <fc-button-primary variant="large" routerLink="/settings/memberships">
                <span>View membership settings</span>
            </fc-button-primary>
        </fc-empty-grid>
    }@else{
        <fc-empty-grid heading="No membership history">
            <p description>Add a new membership to get started.</p>
            <fc-button-primary variant="large" (click)="addMembership()">
                <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
                <span>Add membership</span>
            </fc-button-primary>
        </fc-empty-grid>
    }
}
@else {
    @if (memberships.length > 0) {
    <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
        <fc-button-primary (click)="addMembership()">
            <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
            <span class="hidden sm:flex">Add membership</span>
            <span class="flex sm:hidden">Add</span>
        </fc-button-primary>
    </div>
    }

    @if (activeMemberships.length > 0 && inactiveMemberships.length > 0) {
        <div class="flex justify-between items-center mt-6">
            <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue"/>
        </div>
    }

    <app-student-memberships-grid [memberships]="toggleValue == 'active' ? activeMemberships : inactiveMemberships"/>
    
    <div class="invisible p-4 w-[794px] fixed top-[100vh] ql-editor" #hiddenPdfDiv></div>
}