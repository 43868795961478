import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Membership } from 'src/app/models/membership.model';
import { StudentService } from 'src/app/services/student.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { StudentMembership } from 'src/app/models/student-membership.model';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToasterType } from 'src/app/models/toaster.model';
import { ToasterService } from 'src/app/services/toaster.service';
import * as moment from 'moment';
import { Student } from 'src/app/models/student.model';
import { StudentHistory } from 'src/app/models/student-history.model';


@Component({
  selector: 'app-student-membership-details',
  templateUrl: './student-membership-details.component.html',
  styleUrls: ['./student-membership-details.component.scss']
})
export class StudentMembershipDetailsComponent implements OnInit {
  student: Student | undefined;
  studentMembership: StudentMembership | undefined;
  history: { key: string, values: StudentHistory[] }[] = [];
  loading = true;
  cancelled = false;
  private studentMembershipId: number | undefined;
  private studentId: number | undefined;
  private paymentFrequencies = [
    {id: 1, name: 'Week'},
    {id: 2, name: 'Fortnight'},
    {id: 3, name: 'Month'},
    {id: 4, name: '3-Months'},
    {id: 5, name: '6-Months'},
    {id: 6, name: 'Year'}
  ];

  constructor(private studentService: StudentService, private router: Router, private route: ActivatedRoute, private currencyPipe: CurrencyPipe, private dialog: MatDialog, private toasterService: ToasterService, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.history = [];
    this.studentMembershipId = this.route.snapshot.params['studentMembershipId'];
    this.studentService.getStudentMembership(this.studentMembershipId!).subscribe(result => {
      const groupedHistory = result.history.reduce((group: any, history) => {
        const month = moment(history.date).format("MMMM, yyyy");
        group[month] = group[month] ?? [];
        group[month].push(history);
        return group;
      }, {});

      const months = Object.keys(groupedHistory);
      const sortedMonths = months.sort((a, b) => moment(b).valueOf() - moment(a).valueOf());

      sortedMonths.forEach(x => this.history.push({ key: x, values: groupedHistory[x]}));
      this.studentMembership = result;
      this.loading = false;
    });
  }

  getMembershipName(membership: Membership) {
      const membershipVersion = membership.versions[0];
      const frequency = this.paymentFrequencies.filter(x => x.id == membershipVersion.paymentFrequency)[0].name;
      return `${membership.name} - ${this.currencyPipe.transform(membershipVersion.paymentAmount)} / ${frequency}`;
  }

  cancelMembership() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: { title: 'Cancel membership?', content: `Are you sure you want to cancel this membership? The membership will remain valid until ${this.datePipe.transform(this.studentMembership?.currentPeriodEnd, 'yyyy-MM-dd')}`, noText: 'No', yesText: 'Yes' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.studentService.cancelStudentMembership(this.studentMembership?.id!).subscribe(() => {
          this.loading = false;
          this.cancelled = true;
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Membership cancelled'});
          this.loading = true;
          this.ngOnInit();
        })
      }
    });
  }

  uncancelMembership() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      data: { title: 'Reactivate membership?', content: `Are you sure you want to reactivate this membership? The student will be charged again on ${this.datePipe.transform(this.studentMembership?.currentPeriodEnd, 'yyyy-MM-dd')}`, noText: 'No', yesText: 'Yes' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.studentService.uncancelStudentMembership(this.studentMembership?.id!).subscribe(() => {
          this.loading = false;
          this.cancelled = true;
          this.toasterService.addToaster({type: ToasterType.Success, message: 'Membership uncancelled'});
          this.loading = true;
          this.ngOnInit();
        })
      }
    });
  }

  handleViewDetails(item: any){
    if (item.url) {
      this.router.navigate([item.url])
    } else if (item.details) {
      this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: { title: 'Error details', content: item.details, yesText: 'Close'}
      });
    }
  }
}
