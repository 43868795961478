import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'fc-save-footer',
  templateUrl: './save-footer.component.html',
  styleUrl: './save-footer.component.css'
})
export class SaveFooterComponent {
  @Input() buttonText: string = 'Update';
  @Input() error: boolean = false;
  @Input() loading: boolean = false;

  constructor(private location: Location) {}

  onCancel(): void{
    this.location.back();
  }
}
