<div class="fixed flex flex-col z-40 top-0 left-0 w-16 h-full border-r bg-white gap-6">
    <div class="pt-8">
        <div class="flex flex-none justify-center items-center gap-3">
            <div class="p-1 my-0.5 text-gray-500 cursor-pointer" (click)="collapseClick.emit()">
                <bars-3-outline-icon class="heroicon-sw-1.5" [size]="20" />
            </div>
        </div>
    </div>
    <div class="grow">
        <div class="flex flex-col justify-between h-full">
            <div class="mx-1">
                <a routerLink="/" class="relative flex rounded-lg px-3.5 py-2.5 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <home-outline-icon [size]="20"></home-outline-icon>
                    </div>
                </a>
                <a routerLink="/session/create" class="relative flex rounded-lg px-3.5 py-2.5 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <svg width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 7H1M14 1V4M6 1V4M10 17V11M7 14H13M5.8 21H14.2C15.8802 21 16.7202 21 17.362 20.673C17.9265 20.3854 18.3854 19.9265 18.673 19.362C19 18.7202 19 17.8802 19 16.2V7.8C19 6.11984 19 5.27976 18.673 4.63803C18.3854 4.07354 17.9265 3.6146 17.362 3.32698C16.7202 3 15.8802 3 14.2 3H5.8C4.11984 3 3.27976 3 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1 5.27976 1 6.11984 1 7.8V16.2C1 17.8802 1 18.7202 1.32698 19.362C1.6146 19.9265 2.07354 20.3854 2.63803 20.673C3.27976 21 4.11984 21 5.8 21Z" stroke="#101828" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                            
                        <!-- <calendar-outline-icon [size]="20"></calendar-outline-icon> -->
                    </div>
                </a>
                <a routerLink="/student" class="relative flex rounded-lg px-3.5 py-2.5 mx-1 my-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                    <div class="font-medium flex items-center">
                        <users-outline-icon [size]="20"></users-outline-icon>
                    </div>
                </a>
            </div>
            <div>
                <div class="flex items-center justify-center my-6">
                    <a routerLink="/settings" class="relative flex rounded-lg px-3.5 py-2.5 mx-1 font-normal text-base justify-center text-grey-500 hover:bg-primary-50 hover:text-primary-700" routerLinkActive="bg-primary-50 text-primary-700" [routerLinkActiveOptions]="{exact:true}">
                        <div class="font-medium flex items-center">
                            <cog-6-tooth-outline-icon [size]="20"></cog-6-tooth-outline-icon>
                        </div>
                    </a>
                </div>
                <div *ngIf="user$ | async; let user" class="flex justify-center">
                    <div class="flex gap-3 pt-6 mx-4 mb-8 justify-between border-t">
                        <div class="w-8 h-8 mb-2 cursor-pointer flex justify-center" [matMenuTriggerFor]="profileMenu">
                            <ellipsis-vertical-outline-icon [size]="20" class="text-gray-500 font-medium"/>
                        </div>
                        <mat-menu #profileMenu="matMenu" [overlapTrigger]="false">
                            <button mat-menu-item (click)="logout()">
                                <span>Logout</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </div>