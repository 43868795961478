<div>
    <app-back-button></app-back-button>
</div>
@if (loading) {
    <app-loader class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></app-loader>
}
@else if (user) {
    <div class="mt-6 flex items-center gap-5">
        <div>
            <app-avatar (click)="uploadImage()" class="cursor-pointer" variant="large" [imageUrl]="user.imageUrl" initials="{{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}"></app-avatar>
        </div>
        <div>
            <h1 class="text-3xl font-medium text-gray-900">{{user.firstName}} {{user.lastName}}</h1>
        </div> 
    </div>
    <div class="flex justify-between items-center mt-6">
        <fc-toggle-button-group [options]="toggleOptions" [(selectedValue)]="toggleValue" (selectedValueChange)="onToggleChange($event)"></fc-toggle-button-group>
    </div>
    @if (toggleValue === 'details') {
        <form [formGroup]="userForm" (ngSubmit)="onDetailsSubmit()" class="mt-6">
            <div class="flex flex-col gap-4 w-full sm:w-1/2">
                <fc-text-input formControlName="firstName" [required]="true"  label="First name" [required]="true" [submitted]="submitted"></fc-text-input>
                <fc-text-input formControlName="lastName" [required]="true"  label="Last name" [required]="true" [submitted]="submitted"></fc-text-input>
                <fc-text-input label="Email" [required]="true"  formControlName="email" [submitted]="submitted"><envelope-outline-icon #icon></envelope-outline-icon></fc-text-input>
                <fc-text-input label="Mobile" formControlName="mobile" [submitted]="submitted"><device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon></fc-text-input>
            </div>
            <ng-container *ngIf="userForm.dirty">
                <app-save-footer buttonText="Save Details" 
                    [error]="submitted && !userForm.valid"
                    [loading]="submitting"></app-save-footer>
            </ng-container>
        </form>
    }
    @else if (toggleValue === 'availability') {
        <app-availability-create-form [userId]="user.id" [disciplines]="disciplines"></app-availability-create-form>
    }

    @if (user.isActive) {
        <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
            <fc-button-secondary (click)="archive()">
                <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                <span>Archive user</span>
            </fc-button-secondary>
        </div>
    } 
    @else {
        <div class="flex justify-end align-end gap-3 top-0 right-4 sm:right-8 absolute pt-16 sm:pt-6">
            <fc-button-secondary (click)="archive()">
                <archive-box-outline-icon svgClass="heroicon-sw-1.5" [size]="20"/>
                <span>Restore user</span>
            </fc-button-secondary>
        </div>
    }
}

