<form [formGroup]="sessionCreateForm" (ngSubmit)="onSubmit()">
    <div class="mb-16">
        <ng-container *ngIf="!editing">
            <div class="mt-8">
                <label class="text-sm font-medium text-gray-700">Choose session type</label>
            </div>
            <div class="flex gap-5 mt-2 items-center pb-5 border-b">
                <button type="button" (click)="setPrivate(true)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': isPrivate, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': !isPrivate}">Private session</button>
                <span class="text-gray-700 text-sm font-semibold">or</span>
                <button type="button" (click)="setPrivate(false)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': !isPrivate, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': isPrivate}">Group session</button>
            </div>
        </ng-container>
        <ng-container *ngIf="!isPrivate">
            <fc-text-input class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5"
                formControlName="title"
                label="Title"
                placeholder="Enter a title..."
                [required]="true"
                [submitted]="submitted"
                [errorMessages]="{'required':'Title is required'}"
            />
            <fc-textarea class="w-full sm:w-1/2 flex flex-col gap-1.5 mt-5"
                formControlName="description"
                label="Description"
                placeholder="Enter a description..."
                [rows]="5"
                [submitted]="submitted"
            />
        </ng-container>
        <div class="w-full sm:w-1/2 flex gap-5 mt-5 items-center">
            <div class="w-1/2">
                <fc-select-input
                    label="Disciplines"
                    [options]="disciplines"
                    formControlName="disciplineIds"
                    [submitted]="submitted"
                    [required]="true"
                    [multiple]="true"
                    [errorMessages]="{required:'Disciplines is required'}"
                />
            </div>
        </div>
        <div class="w-full sm:w-1/2 flex items-center mt-5 gap-2">
            <mat-label class="block text-sm font-medium text-gray-700">Contributes to rank progress?</mat-label>
            <mat-slide-toggle formControlName="trackProgress"
            class="relative inline-flex items-center rounded-full transition-colors"
            [color]="'primary'"
            [disableRipple]="true">
          </mat-slide-toggle>
        </div>
        <ng-container *ngIf="!editing && !isPrivate">
            <div class="mt-5 border-t pt-5">
                <label class="text-sm font-medium text-gray-700">Is this a once off?</label>
            </div>
            <div class="flex gap-5 mt-2 items-center">
                <button type="button" (click)="setType(1)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': type == 1, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': type == 2}">Single session</button>
                <span class="text-gray-700 text-sm font-semibold">or</span>
                <button type="button" (click)="setType(2)" [ngClass]="{'rounded-lg border px-3.5 py-2': true, 'bg-primary-600 text-white border-primary-600 hover:bg-primary-700 focus:ring focus:ring-primary-100 focus:outline-0': type == 2, 'border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0': type == 1}">Recurring session</button>
            </div>
        </ng-container>
        <div class="flex flex-col sm:flex-row mt-5 gap-5" [class.mb-12]="!isPrivate && type == 1">
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Date</label>
                <input [value]="defaultDate" (click)="date.open()" [matDatepicker]="date" (dateChange)="dateChange($event)"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-36 hover:bg-gray-50"/>
                <calendar-outline-icon (click)="date.open()" class="absolute mt-9 right-10 sm:right-auto sm:ml-28 w-6 text-gray-500"></calendar-outline-icon>
                <mat-datepicker  touchUi #date></mat-datepicker>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Start</label>
                <input [value]="defaultStartTime" [ngxTimepicker]="startTime" class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-32 hover:bg-gray-50">
                <ngx-material-timepicker [theme]="timePickerTheme" (timeChanged)="timeChanged('startTime', $event)" #startTime></ngx-material-timepicker>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Finish</label>
                <input [value]="defaultEndTime" [ngxTimepicker]="endTime"  class="border border-gray-300 focus:border-primary-300 focus:outline-0 focus:ring focus:ring-primary-100 text-sm px-4 py-3 rounded-lg w-full sm:w-32 hover:bg-gray-50">
                <ngx-material-timepicker [theme]="timePickerTheme" (timeChanged)="timeChanged('endTime', $event)" #endTime></ngx-material-timepicker>
            </div>
        </div>
        <div *ngIf="!isPrivate && type == 2 && !editing" class="flex flex-col sm:flex mt-5 gap-5">
            <div class="flex flex-col gap-1.5 w-full sm:w-40">
                <fc-select-input
                    label="Frequency"
                    [options]="frequencies"
                    (selectionChange)="frequencyChange($event)"
                    [firstOptionDefault]="true">
                </fc-select-input>
            </div>
            <div class="flex flex-col gap-1.5">
                <label class="text-sm font-medium text-gray-700">Number of sessions to create</label>
                <input formControlName="occurances" defaultValue="1" type="number" min="0" [max]="maxSessions" name="occurances" [ngClass]="{'border sm:w-20 focus:outline-0 focus:ring text-sm px-4 py-3 rounded-lg w-full hover:bg-gray-50': true, 'border-error-300 focus:border-error-300 focus:ring-error-300': submitted && !sessionCreateForm.get('occurances')?.valid ?? false, 'border-gray-300 focus:border-primary-300 focus:ring-gray-100': !submitted || sessionCreateForm.get('occurances')?.valid ?? true}" />
                <span [ngClass]="{'text-sm': true, 'text-gray-500': !submitted || sessionCreateForm.get('occurances')?.valid ?? true, 'text-error-500': submitted && !sessionCreateForm.get('occurances')?.valid ?? false}" class="text-gray-500 text-sm">{{ 'Max ' + maxSessions }}</span>
            </div>
        </div>
        <div *ngIf="isPrivate" class="flex flex-col gap-5 mt-5">
            <div class="w-full sm:w-1/2">
                <fc-auto-complete-input
                    label="Add student"
                    placeholder="Search for a student..."
                    [formControl]="autoCompleteControl"
                    [data]="students"
                    [displayWith]="studentSearchDisplay"
                    (optionSelected)="optionSelected($event)">
                </fc-auto-complete-input>
            </div>
            <div class="border overflow-hidden rounded-lg">
                <table class="w-full">
                    <thead class="bg-gray-50 text-gray-500">
                        <tr class="text-left">
                            <th class="pl-6 py-3 w-1/4 font-medium">
                                Name
                            </th>
                            <th class="pl-6 py-3 font-medium">
                                Rank
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody class="[&>*:not(:first-child)]:border-t">
                        <tr *ngFor="let student of selectedStudents" class="hover:bg-gray-50">
                            <td class="px-6 py-4 cursor-pointer" >
                                <div class="flex items-center gap-3">
                                    <div>
                                        <app-avatar [imageUrl]="student.imageUrl" [initials]="student.firstName.charAt(0).toUpperCase() + student.lastName.charAt(0).toUpperCase()"></app-avatar>
                                    </div>
                                    <div class="flex flex-col">
                                        <span class="font-medium">{{student.firstName}} {{student.lastName}} <span *ngIf="!student.isActive">(Archived)</span></span>
                                        <span class="text-gray-500">{{student.email}}</span>
                                    </div>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <div class="flex flex-col justify-center">
                                    <div>
                                        <ng-container *ngFor="let d of student.disciplines">
                                            <span *ngIf="(selectedDisciplines?.indexOf(d.discipline) ?? -1) >= 0" class="grow-0 px-2 py-1 text-gray-700 font-medium text-xs rounded-2xl bg-gray-50">
                                            {{d.grade}}
                                            </span>
                                        </ng-container>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="flex justify-end">
                                    <trash-outline-icon (click)="removeStudent(student.id)"  class="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="selectedStudents.length === 0">
                            <td class="px-6 py-4" [class.text-gray-500]="!privateStudentError" [class.text-error-500]="privateStudentError" colSpan="3">
                                You need to add in a student...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <app-save-footer *ngIf="sessionCreateForm.dirty" [buttonText]="editing ? 'Update session' : 'Create session'" [error]="submitted && !sessionCreateForm.valid" [loading]="loading"></app-save-footer>
</form>