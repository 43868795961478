import { Component } from '@angular/core';

@Component({
  selector: 'fc-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css'
})
export class LoaderComponent {

}
