<fc-grid [columns]="columns" [data]="users" [actionTemplate]="actionTemplate">
</fc-grid>

<ng-template #actionTemplate let-row>
    <div class="flex justify-end gap-2">
        <fc-button-secondary [routerLink]="[row.id]">
            View
        </fc-button-secondary>
    </div>
</ng-template>

<ng-template #nameTemplate let-row>
    <div class="flex items-center gap-3"> 
        <div>
            <app-avatar [imageUrl]="row.imageUrl" initials="{{row.firstName.charAt(0).toUpperCase()}}{{row.lastName.charAt(0).toUpperCase()}}"></app-avatar>
        </div>
        <div class="flex flex-col">
            <span class="text-sm font-medium text-gray-900">
                {{ row.firstName }} {{ row.lastName }}
            </span>
        </div>
    </div>
</ng-template>
