import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionInstance } from '../models/session-instance.model';
import { Calendar } from '../models/calendar.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http: HttpClient) { 
  }

  createSession(session: any) {
    return this.http.post<number>(`${environment.baseUrl}/session`, session);
  }

  updateSessionInstance(id: number, sessionInstance: any, includeRecurring: boolean = false) {
    return this.http.put(`${environment.baseUrl}/session/sessioninstance/${id}?includeRecurring=${includeRecurring}`, sessionInstance);
  }

  deleteSessionInstance(id: number, includeRecurring: boolean = false) {
    return this.http.delete(`${environment.baseUrl}/session/sessioninstance/${id}?includeRecurring=${includeRecurring}`);
  }

  getSessionInstance(id: number) {
    return this.http.get<SessionInstance>(`${environment.baseUrl}/session/sessioninstance/${id}`);
  }

  getCalendar(startDate: string, endDate: string) {
    return this.http.post<Calendar[]>(`${environment.baseUrl}/session/calendar`, { startDate: startDate, endDate: endDate });
  }

  createCheckIn(sessionInstanceId: number, studentId: number, override: boolean = false) {
    return this.http.post(`${environment.baseUrl}/session/checkin/${sessionInstanceId}`, { studentId: studentId, override: override });
  }

  deleteCheckIn(sessionInstanceId: number, studentId: number) {
    return this.http.delete(`${environment.baseUrl}/session/checkin/${sessionInstanceId}/${studentId}`);
  }
}
