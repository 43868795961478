import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string, content: string, noText: string, yesText: string, yesColourRed?: boolean, cancelButton: string }) {
    if (data.yesColourRed === undefined) {
      data.yesColourRed = false;
    }
  }
}
