<h1 class="text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
  {{data.title}}
</h1>
<div class="pb-4 px-6 text-sm text-gray-500" [innerHTML]="data.content">
</div>
<div class="flex justify-center pt-4 pb-6 px-6 gap-3">
  @if (data.cancelButton)
  {
  <button *ngIf="data.cancelButton"
    class="min-w-[70px] text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center"
    [mat-dialog-close]="undefined">{{data.cancelButton}}</button>
  }
  <button *ngIf="data.noText"
    class="min-w-[70px] text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center"
    [mat-dialog-close]="false">{{data.noText}}</button>
  <button *ngIf="data.yesText" [mat-dialog-close]="true" [ngClass]="{  'min-w-[70px] text-white border hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center' : true,
                'bg-error-600 border-error-600' : data.yesColourRed,
                'bg-primary-600 border-primary-300' : !data.yesColourRed }">{{data.yesText}}</button>
</div>