<h1 class="text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
    Upload a photo
</h1>
<div  class="pb-4 px-6 text-sm text-gray-500">
    Upload and attach a photo<span *ngIf="data?.studentName"> for {{data.studentName}}</span>.
</div>
<div class="mx-6 p-8 border border-gray-200 rounded-lg">
    <ng-container *ngIf="!image">
        <div  class="flex justify-center">
            <div class="w-10 h-10 bg-gray-100 rounded-full flex justify-center items-center">
                <cloud-arrow-up-outline-icon class="h-5" ></cloud-arrow-up-outline-icon>
            </div>
        </div>
        <div class="flex flex-col items-center mt-3">
            <p class="text-gray-500">
                <span (click)="uploadImage()" class="text-primary-700 font-semibold cursor-pointer">
                Click to upload
                </span> 
                <span>or drag and drop</span>
            </p>
            <p class="text-gray-500">PNG or JPG (max. 4MB)</p>
        </div>
    </ng-container>
    <ng-container *ngIf="image">
        <div class="flex justify-center w-[300px] h-[300px]">
            <angular-cropper class="cropper w-[300px] h-[300px]" [cropperOptions]="cropperOptions" [imageUrl]="image" #cropper></angular-cropper>
        </div>
    </ng-container>
    <input type="file" (change)="handleImageUpload($event)" accept="image/*" style="display: none" #hiddenFileInput/>
</div>
<div class="flex pt-4 pb-6 px-6 gap-3">
    <button [mat-dialog-close]="null"
      type="button"
      class="grow text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center"
    >
      Cancel
    </button>
    <button
    (click)="getCropData()"
      type="button"
      class="grow bg-primary-600 text-white border border-primary-300 hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center"
    >
      Upload
    </button>
  </div>