import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GymTermsAndConditions } from 'src/app/models/terms-and-conditions.model';

@Component({
  selector: 'app-terms-and-conditions-modal',
  templateUrl: './terms-and-conditions-modal.component.html',
  styleUrl: './terms-and-conditions-modal.component.scss'
})
export class TermsAndConditionsModalComponent {
  accepted = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: GymTermsAndConditions) {}

  viewTermsAndConditions() {
    const backButton = "<button onclick='window.close()'>Go back</button>"
    const tab = window.open("about:blank");

    tab?.document.writeln(backButton);
    tab?.document.writeln(this.data.content);
  }

  get overdue() {
    return new Date(this.data.effectiveDate) <= new Date();
  }
}
