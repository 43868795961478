import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Student } from 'src/app/models/student.model';
import { StudentDocument } from 'src/app/models/student-document.model';
import { StudentService } from 'src/app/services/student.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, tap } from 'rxjs';
import { DocumentService } from 'src/app/services/document.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-student-documents',
  templateUrl: './student-documents.component.html',
  styleUrls: ['./student-documents.component.scss']
})
export class StudentDocumentsComponent implements OnInit {
  loading: boolean = true;
  studentId!: number;
  student!: Student;
  requiredDocs: StudentDocument[] = [];
  currentDocs: StudentDocument[] = [];
  previousDocs: StudentDocument[] = [];

  toggleOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Historic', value: 'historic' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(private route: ActivatedRoute, private studentService: StudentService, private documentService: DocumentService) { }

  get activeDocs(): StudentDocument[] {
    return [...this.requiredDocs, ...this.currentDocs];
  }

  get historicDocs(): StudentDocument[] {
    return this.previousDocs;
  }

  ngOnInit(): void {
    const id = this.route.parent?.snapshot.params['id'];
    if (id) {
      this.studentId = +id;
      forkJoin([
        this.studentService.getStudent(this.studentId),
        this.studentService.getStudentDocuments(this.studentId)
      ]).pipe(
        tap(([student, results]) => {
          this.student = student;
          this.requiredDocs = results.filter(d => !d.isArchived && d.isCurrent && d.studentDocumentId == null);
          this.currentDocs = results.filter(d => !d.isArchived && d.isCurrent && d.studentDocumentId != null);
          this.previousDocs = results.filter(d => (!d.isCurrent || d.isArchived) && d.studentDocumentId != null);
        })
      ).subscribe(() => {
        if (this.activeDocs.length == 0 && this.previousDocs.length > 0) {
          this.toggleValue = this.toggleOptions[1].value;
        }
        this.loading = false;
      });
    }
  }

  sendEmail(documentVersionId: number) {
    this.documentService.sendDocument(this.studentId, documentVersionId).subscribe(result => {
      navigator.clipboard.writeText(result)
    });
  }

  downloadStudentDocument(studentDocument: StudentDocument) {
    this.documentService.downloadStudentDocument(studentDocument.studentDocumentId!).subscribe(html => {
      const x = document.createElement('div');
      x.classList.add('px-4', 'w-[600px]', 'fixed', 'ql-editor'); 
      x.innerHTML = html;
      setTimeout(() => {
        var doc = new jspdf('p', 'pt', 'a4', true);
        doc.html(x, { autoPaging: 'text', callback: (doc: jspdf) => {
          doc.save(`${this.student.firstName}-${this.student.lastName}_${studentDocument.name}.pdf`)
        }});
      }, 0);
    });
  }
}