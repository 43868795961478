<form [formGroup]="form" (ngSubmit)="confirmDialog()">
    <h1 class="select-none text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
      Add user
    </h1> 
    <div class="flex flex-col gap-1.5 mt-4 px-6">
        <div class="w-full flex justify-center">
          <div (click)="uploadImage()"
              class="cursor-pointer inline-flex items-center justify-center w-[160px] h-[160px] overflow-hidden bg-primary-50 hover:bg-primary-200 rounded-full">
              <img *ngIf="imageUrl" [src]="imageUrl" class="w-full h-full object-cover" />
              <user-outline-icon *ngIf="!imageUrl" class="p-10" svgClass="stroke-1 stroke-primary-600" [size]="80"></user-outline-icon>
          </div>
        </div>
        <fc-text-input label="First name" [required]="true" formControlName="firstName" [submitted]="submitted" />
        <fc-text-input label="Last name" [required]="true"  formControlName="lastName" [submitted]="submitted" />
        <fc-text-input label="Email" [required]="true"  formControlName="email" [submitted]="submitted"><envelope-outline-icon #icon></envelope-outline-icon></fc-text-input>
        <fc-text-input label="Mobile" formControlName="mobile" [submitted]="submitted"><device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon></fc-text-input>
    </div>
    <div class="flex pt-4 pb-6 px-6 gap-3">
      <button
        type="button"
        class="h-[38px] grow text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center"
        (click)="closeDialog()">
        Cancel
      </button>
      <button
        type="submit"
        class="h-[38px] grow bg-primary-600 text-white border border-primary-300 hover:bg-primary-70 focus:ring focus:ring-error-100 focus:outline-0 font-medium rounded-lg text-sm px-3 py-1.5 text-center">
        Confirm
      </button>
    </div>
  </form>