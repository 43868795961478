<h1 class="text-lg font-semibold text-gray-900 mb-2 pt-6 px-6">
    Action: Review and accept our terms & conditions
</h1>
<div class="pb-4 px-6 text-sm text-gray-600 flex flex-col gap-3">
    <p>To continue using FightCloud you'll need to review and agree to our terms and conditions.</p>
    @if (!overdue)
    {
    <p>These updated terms will come into effect as of {{data.effectiveDate | date:'longDate'}}. If you have not accepted before this date, you will be unable to use FightCloud.</p>
    }
    @else
    {
    <p>These updated terms were announced on {{data.announcedDate | date:'longDate'}} and came into effect as of {{data.effectiveDate | date:'longDate'}}.</p>
    }
    @if (data.email)
    {
        <p>If you do not wish to accept the terms & conditions, please <a class="text-blue-600 underline" href="mailto:fightcloud.info@gmail.com">get in contact.</a></p>
    }
    <div class="flex gap-2 items-center mt-2">
        <input type="checkbox" [(ngModel)]="accepted"
            class="w-4 h-4 text-gray-50 border-gray-300 rounded focus:ring-0 checked:border-primary-600 checked:text-purple-600" />
        <span class="text-gray-700 font-medium text-sm">
            I have read and understood the terms and conditions and I accept
        </span>
    </div>
</div>

<div class="flex justify-center pt-4 pb-6 px-6 gap-3">
    <button (click)="viewTermsAndConditions()"
        class="text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center"
        >View terms and conditions</button>
    @if (!overdue)
    {
    <button
        class="text-gray-700 border border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center"
        [mat-dialog-close]="0">Remind me later</button>
    }
    <button [disabled]="!accepted"
        class="disabled:bg-gray-600 bg-primary-600 text-white border border-gray-300 hover:bg-primary-700 focus:ring focus:ring-gray-100 focus:outline-0 font-medium rounded-lg px-3 py-2.5 text-center"
        [mat-dialog-close]="1">Accept</button>
</div>