import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, startWith, map } from 'rxjs';

@Component({
  selector: 'fc-auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrl: './auto-complete-input.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteInputComponent),
      multi: true
    }
  ]
})
export class AutoCompleteInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = 'Search...';
  @Input() data: any[] = [];
  @Input() displayWith: (item: any) => string = (item) => item.toString();
  @Output() optionSelected = new EventEmitter<any>();

  formControl = new FormControl();
  filteredOptions: Observable<any[]> = of([]);

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  ngOnInit(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );

    this.formControl.valueChanges.subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  filter(value: string): any[] {
    if (!value || typeof value !== 'string') return [];
    const filterValue = value.toLowerCase();
    return this.data.filter((option) =>
      this.displayWith(option).toLowerCase().includes(filterValue)
    );
  }

  writeValue(value: any): void {
    this.formControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onOptionSelected(event: any) {
    this.formControl.setValue(event.option.value);
    this.onChange(event.option.value);
    this.optionSelected.emit(event);
  }
}
