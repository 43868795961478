import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ToasterService } from './toaster.service';
import { TermsAndConditionsModalComponent } from '../components/modals/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { GymTermsAndConditions } from '../models/terms-and-conditions.model';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  constructor(private http: HttpClient, private dialog: MatDialog, private toasterService: ToasterService) { }

  checkTermsAndConditions() {
    this.http.get<GymTermsAndConditions | null>(`${environment.baseUrl}/gym/terms-and-conditions`).subscribe(result => {
      if (result == null) {
        return;
      }

      this.createTermsAndConditionsModal(result);
    });
  }

  acceptTermsAndConditions(id: number) {
    return this.http.post(`${environment.baseUrl}/gym/terms-and-conditions/${id}`, null);
  }

  remindTermsAndConditions(id: number) {
    return this.http.post(`${environment.baseUrl}/gym/terms-and-conditions/remind/${id}`, null);
  }

  createTermsAndConditionsModal(termsAndConditions: GymTermsAndConditions) {
    const dialogRef = this.dialog.open(TermsAndConditionsModalComponent, {
        width: '700px',
        data: termsAndConditions,
        disableClose: new Date(termsAndConditions.effectiveDate) <= new Date()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 0) {
        this.remindTermsAndConditions(termsAndConditions.id).subscribe(() => {});
      }

      if (result === 1) {
        this.acceptTermsAndConditions(termsAndConditions.id).subscribe(() => {});
      }
    });
  }
}
