import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StudentGrid } from '../models/student-grid.model';
import { Student } from '../models/student.model';
import { StudentSearch } from '../models/student-search.model';
import { Observable } from 'rxjs';
import { StudentGrade } from '../models/student-grade.model';
import { StudentNote } from '../models/student-note.model';
import { StudentDocument } from '../models/student-document.model';
import { DocumentVersion } from '../models/document.model';
import { StudentMembership, StudentMembershipGrid } from '../models/student-membership.model';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient) { 
  }

  createStudent(student: any) {
    return this.http.post<number>(`${environment.baseUrl}/student`, student);
  }

  getStudents() {
    return this.http.get<StudentGrid[]>(`${environment.baseUrl}/student`);
  }

  getStudent(id: number) {
    return this.http.get<Student>(`${environment.baseUrl}/student/${id}`);
  }

  updateStudent(id: number, student: any) {
    return this.http.put(`${environment.baseUrl}/student/${id}`, student);
  }

  getSearchStudents(disciplineIds: number[] = []): Observable<StudentSearch[]> {
    return this.http.post<StudentSearch[]>(`${environment.baseUrl}/student/search`, {disciplineIds: disciplineIds});
  }

  getStudentGrades(id: number) {
    return this.http.get<StudentGrade[]>(`${environment.baseUrl}/student/${id}/grades`);
  }

  updateStudentGrade(id: number, studentGradeId: number, studentGrade: any) {
    return this.http.put(`${environment.baseUrl}/student/${id}/grade/${studentGradeId}`, studentGrade)
  }

  createStudentGrade(id: number, studentGrade: any) {
    return this.http.post(`${environment.baseUrl}/student/${id}/grade`, studentGrade)
  }

  getStudentNotes(id: number) {
    return this.http.get<StudentNote[]>(`${environment.baseUrl}/student/${id}/notes`)
  }

  archiveStudent(id: number) {
    return this.http.post(`${environment.baseUrl}/student/${id}/archive`, null);
  }
 
  getStudentDocuments(id: number) {
    return this.http.get<StudentDocument[]>(`${environment.baseUrl}/student/${id}/documents`)
  }

  getStudentDocumentVersion(id: number, documentVersionId: number) {
    return this.http.get<DocumentVersion>(`${environment.baseUrl}/student/${id}/document/${documentVersionId}`)
  }

  createStudentMembership(studentId: number, membership: any) {
    return this.http.post(`${environment.baseUrl}/studentmembership`, { studentId: studentId,  membershipId: membership.membership.id, startDate: membership.startDate });
  }

  resendMembership(studentMembershipId: number) {
    return this.http.post(`${environment.baseUrl}/studentmembership/resend/${studentMembershipId}`, {});
  }

  getStudentMemberships(studentId: number) {
    return this.http.get<StudentMembershipGrid[]>(`${environment.baseUrl}/studentmembership/student/${studentId}`);
  }

  getStudentMembership(studentMembershipId: number) {
    return this.http.get<StudentMembership>(`${environment.baseUrl}/studentmembership/${studentMembershipId}`);
  }

  cancelStudentMembership(studentMembershipId: number) {
    return this.http.post<StudentMembership>(`${environment.baseUrl}/studentmembership/${studentMembershipId}/cancel`, {});
  }

  uncancelStudentMembership(studentMembershipId: number) {
    return this.http.post<StudentMembership>(`${environment.baseUrl}/studentmembership/${studentMembershipId}/uncancel`, {});
  }
}
