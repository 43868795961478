import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

@Component({
  selector: 'fc-timeline-item',
  templateUrl: './timeline-item.component.html',
})
export class TimelineItemComponent {
  @Input() item: any;
  @Input() isLast: boolean = false;
  @Input() iconTemplate?: TemplateRef<any>;
  @Output() actionTriggered = new EventEmitter<any>();

  triggerAction(item: any) {
    this.actionTriggered.emit(item);
  }
}
