import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddUserModalComponent } from 'src/app/components/users/add-user-modal/add-user-modal.component';
import { ToasterType } from 'src/app/models/toaster.model';
import { User } from 'src/app/models/user.model';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-users',
  templateUrl: './settings-users.component.html',
  styleUrl: './settings-users.component.scss'
})
export class SettingsUsersComponent {
  activeUsers: User[] = [];
  archivedUsers: User[] = [];
  toggleOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
  ];
  toggleValue = this.toggleOptions[0].value;

  loading: boolean = true;

  constructor(private userService: UserService, private matDialog: MatDialog, private toasterService: ToasterService) {

  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe({
      next: users => {
        this.activeUsers = users.filter(x => x.isActive);
        this.archivedUsers = users.filter(x => !x.isActive);

        if (this.activeUsers.length == 0 && this.archivedUsers.length > 0) {
          this.toggleValue = this.toggleOptions[1].value;
        }

        this.loading = false;
      }
    });
    
  }

  addUser() {
    const dialogRef = this.matDialog.open(AddUserModalComponent, {
      data: { title: 'Add user' },
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      
      this.userService.createUser(result).subscribe({
        next: id => {
          result.id = id;
          this.activeUsers.push(result);
          this.toasterService.addToaster({type: ToasterType.Success, message: 'User created'})
        }
      });
    });
  }
}
