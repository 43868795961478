<div class="flex flex-row gap-4">
    <!-- Icon & Line -->
    <div class="relative flex flex-col items-center">
        <!-- Custom Icon (if provided) -->
        <ng-container *ngIf="iconTemplate">
            <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: item }"></ng-container>
        </ng-container>

        <!-- Default Icon Fallback -->
        <div *ngIf="!iconTemplate" class="w-12 h-12 p-3 bg-white rounded-lg shadow border border-gray-200 flex justify-center items-center">
            <div class="w-6 h-6" [ngSwitch]="item.type">
                <rocket-launch-outline-icon *ngSwitchCase="1"/>
                <user-circle-outline-icon *ngSwitchCase="2"/>
                <trophy-outline-icon *ngSwitchCase="3"/>
                <currency-dollar-outline-icon *ngSwitchCase="4"/>
                <stop-circle-outline-icon *ngSwitchCase="5"/>
                <plus-circle-outline-icon *ngSwitchCase="6"/>
                <plus-circle-outline-icon *ngSwitchCase="7"/>
                <check-circle-outline-icon *ngSwitchCase="8"/>
                <check-circle-outline-icon *ngSwitchCase="9"/>
                <currency-dollar-outline-icon *ngSwitchCase="10"/>
                <exclamation-triangle-outline-icon *ngSwitchCase="11"/>
                <clock-outline-icon *ngSwitchCase="12"/>
            </div>
        </div>

        <!-- Vertical Line (Only if not last in this group) -->
        <div *ngIf="!isLast" class="border-gray-300 border-l my-[0.25rem] grow ml-[1px] min-h-6" 
            style="transform: translateX(-50%);">
        </div>
    </div>

    <!-- Event Details -->
    <div class="flex-grow flex flex-col gap-1 mt-0.5" [class.mb-8]="!isLast">
        <div class="text-slate-700 text-sm font-semibold">{{ item.title }}</div>
        <div class="text-slate-600 text-sm">
            {{ item.message }}
            <a *ngIf="item.url || item.details" class="text-primary-600 cursor-pointer" (click)="triggerAction(item)">
                View details.
            </a>
        </div>
    </div>
</div>
