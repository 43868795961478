import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/modals/confirm-modal/confirm-modal.component';
import { UploadImageModalComponent } from 'src/app/components/modals/upload-image-modal/upload-image-modal.component';
import { Availability } from 'src/app/models/availability.model';
import { Discipline } from 'src/app/models/discipline.model';
import { ToasterType } from 'src/app/models/toaster.model';
import { User } from 'src/app/models/user.model';
import { DisciplineService } from 'src/app/services/discipline.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-settings-users-view',
  templateUrl: './settings-users-view.component.html',
  styleUrl: './settings-users-view.component.scss'
})
export class SettingsUsersViewComponent {
  loading: boolean = false;
  user: User | undefined;
  userId: number | undefined;
  userForm: FormGroup;
  availability: Availability[] = [];
  disciplines: Discipline[] = [];
  submitted = false;
  submitting = false;

  toggleOptions = [
    { label: 'Details', value: 'details' },
    { label: 'Availability', value: 'availability' },
  ];
  toggleValue = this.toggleOptions[0].value;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private disciplineService: DisciplineService,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobile: [''],
      imageUrl: ['']
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    const id = this.route.snapshot.paramMap.get('id');
  
    if (id) {
      this.userId = +id;
  
      // Combine user and availability fetch requests
      forkJoin({
        user: this.userService.getUser(this.userId),
        availability: this.userService.getAvailability(this.userId),
        disciplines: this.disciplineService.getDisciplines()
      }).subscribe({
        next: ({ user, availability, disciplines }) => {
          this.user = user;
  
          // Patch user data to form
          this.userForm.patchValue(user);
  
          // Pass availabilities to the form component
          this.availability = availability;

          this.disciplines = disciplines;
  
          this.loading = false;
        },
        error: () => {
          this.loading = false;
          this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to load data' });
        }
      });
    }
  }

  onDetailsSubmit() {
    this.submitted = true;
    this.submitting = true;
    if (this.userForm.valid && this.userId) {
      const updatedUser = { ...this.user, ...this.userForm.value };
      this.userService.updateUser(this.userId, updatedUser).subscribe(() => {
        this.toasterService.addToaster({ type: ToasterType.Success, message: 'User details updated successfully' });
        this.userForm.markAsPristine();
        this.submitted = false;
      });
    }
    this.submitting = false;
  }

  onToggleChange(value: string): void {
    if (value !== 'details') {
      if (this.userForm.dirty) {
        this.userForm.reset(this.user);
      }
    }
  }

  archive() {
    if (this.userId) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: "Are you sure?",
          content: this.user?.isActive ? "Are you sure you want to archive this user? The user will not be able to log into FightCloud." : "Are you sure you want to restore this user? The user will be able to log into FightCloud.",
          yesText: this.user?.isActive ? "Delete" : "Restore",
          noText: "Cancel",
          yesColourRed: this.user?.isActive
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (this.user?.isActive) {
            this.userService.archiveUser(this.userId!).subscribe({          
              next: () => {
                if (this.user) {
                  this.user.isActive = false;
                }
                this.toasterService.addToaster({ type: ToasterType.Success, message: 'User archived' });
              },
              error: () => {
                this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to archive user' });
              }
            });
          } else {
            this.userService.restoreUser(this.userId!).subscribe({          
              next: () => {
                if (this.user) {
                  this.user.isActive = true;
                }
                this.toasterService.addToaster({ type: ToasterType.Success, message: 'User restored' });
              },
              error: () => {
                this.toasterService.addToaster({ type: ToasterType.Error, message: 'Failed to restore user' });
              }
            });
          }
        }
      });
    }
  }

  uploadImage() {
      const dialogRef = this.dialog.open(UploadImageModalComponent, { width: '400px' });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.userForm.get('imageUrl')?.setValue(`${environment.blobBaseUrl}/avatar/${result}`);
          this.userForm.markAsDirty();
          if (this.user) {
            this.user.imageUrl = `${environment.blobBaseUrl}/avatar/${result}`;
          }
        }
      });
  }
}
