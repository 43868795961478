<form [formGroup]="studentCreateForm" (ngSubmit)="onSubmit()">
    <div class="mt-4 mb-20">
        
        <!-- Student Details Section -->
        <div class="mb-2 font-semibold text-lg text-gray-900">Student details</div>
        <div class="flex flex-col sm:flex-row pb-4 gap-4">

            <!-- First Name, Last Name and DOB  -->
            <div class="w-full sm:w-[350px] flex flex-col gap-4 order-2 sm:order-1">
                <fc-text-input
                    label="First Name"
                    formControlName="firstName"
                    placeholder="Required..."
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required: 'First Name is required'}"
                ></fc-text-input>
                <fc-text-input
                    label="Last Name"
                    formControlName="lastName"
                    placeholder="Required..."
                    [required]="true"
                    [submitted]="submitted"
                    [errorMessages]="{required: 'Last Name is required'}"
                ></fc-text-input>
                <div>
                    <fc-text-input
                        [label]="'Date of birth'"
                        [type]="'text'"
                        [matDatepicker]="date"
                        [placeholder]="'Select your date of birth'"
                        formControlName="dateOfBirth"
                        [submitted]="submitted"
                        (click)="date.open()"
                        (dateChange)="dateChange($event)"
                    ></fc-text-input>
                    <mat-datepicker touchUi #date></mat-datepicker>
                </div>
            </div>

            <!-- Profile Picture -->
            <div class="order-1 sm:order-2 flex justify-center sm:w-[350px] items-center">
                <div (click)="uploadImage()"
                    class="cursor-pointer inline-flex items-center justify-center w-[160px] h-[160px] overflow-hidden bg-primary-50 hover:bg-primary-200 rounded-full">
                    <img *ngIf="imageUrl" [src]="imageUrl" class="w-full h-full object-cover" />
                    <user-outline-icon *ngIf="!imageUrl" class="p-10" svgClass="stroke-1 stroke-primary-600" [size]="80"></user-outline-icon>
                </div>
            </div>
        </div>

        <!-- Email and Mobile -->
        <div class="pb-10 flex flex-col sm:flex-row gap-4">
            <fc-text-input class="w-full sm:max-w-[350px]" 
                label="Email" 
                formControlName="email" 
                [submitted]="submitted" 
                [errorMessages]="{'required':'Please enter a valid email'}"
            >
                <envelope-outline-icon #icon></envelope-outline-icon>
            </fc-text-input>
            <fc-text-input class="w-full sm:max-w-[350px]"
                label="Mobile"
                formControlName="mobile"
                [submitted]="submitted"
                [errorMessages]="{pattern: 'Please enter a valid mobile'}"
            >
                <device-phone-mobile-outline-icon #icon></device-phone-mobile-outline-icon>
            </fc-text-input>
        </div>

        <!-- Emergency Contact Information Section -->
        <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Emergency contact information</div>
        <div formGroupName="emergencyContact" class="pb-10">
            <div class="flex flex-col sm:flex-row gap-5">
                <fc-text-input class="w-full sm:max-w-[350px]"
                    label="Emergency Contact First Name"
                    formControlName="emergencyFirstName"
                ></fc-text-input>
                <fc-text-input class="w-full sm:max-w-[350px]"
                    label="Emergency Contact Last Name"
                    formControlName="emergencyLastName"
                ></fc-text-input>
            </div>
            <div class="mt-5 flex flex-col sm:flex-row gap-5">
                <fc-text-input class="w-full sm:max-w-[350px]"
                    label="Emergency Contact Relationship"
                    formControlName="emergencyRelationship"
                    [submitted]="submitted"
                ></fc-text-input>
                <fc-text-input class="w-full sm:max-w-[350px]"
                    label="Emergency Contact Mobile"
                    formControlName="emergencyMobile"
                    [submitted]="submitted"    
                    [errorMessages]="{pattern: 'Please enter a valid mobile'}"
                >
                    <device-phone-mobile-outline-icon #icon svgClass="w-5 h-5 text-gray-500 dark:text-gray-400"></device-phone-mobile-outline-icon>
                </fc-text-input>
            </div>
        </div>

        <!-- Emergency Information Section -->
        <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Emergency information</div>
        <div class="w-full sm:max-w-[720px] pb-10">        
            <fc-textarea
                label="Notes"
                formControlName="emergencyInformation"
                placeholder="Outline important information regarding the students safety or ability to train."
                [rows]="5"
            />
        </div>

        @if (!student) {
        <!-- Training History Section -->
        <div>
            <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Training history</div>
            <div class="text-gray-600 text-sm mb-2">Add previous discipline experience and choose if you’ll be managing the students rank</div>
        </div>
        <div class="mb-10">
            <button *ngIf="disciplinesAvailable.length > 0" (click)="addTrainingHistory()" type="button" class="flex rounded-lg border px-3.5 py-2 border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
                Add training
            </button>
        </div>
        <div *ngIf="selectedDisciplines.length > 0" class="my-4 border overflow-y-hidden rounded-lg">
            <table class="w-full overflow-scroll">
                <thead class="bg-gray-50 text-gray-500">
                    <tr class="text-left">
                        <th class="pl-6 py-3 w-1/4 font-medium">
                            Discipline
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Rank
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Date
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Managing their rank?
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="[&>*:not(:first-child)]:border-t">
                    <tr class="hover:bg-gray-50" *ngFor="let discipline of selectedDisciplines">
                        <td class="px-6 py-4 cursor-pointer">
                            <span class="font-medium">{{discipline.disciplineName}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.gradeName}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.gradeDate | date}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{discipline.manageRank ? 'Yes' : 'No'}}</span>
                        </td>
                        <td>
                            <div class="flex justify-end">
                                <trash-outline-icon (click)="removeTrainingHistory(discipline.disciplineId)" svgClass="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- Membership Section -->
        <div>
            <div class="border-t pt-2 mb-2 font-semibold text-lg text-gray-900">Membership</div>
            <div class="text-gray-600 text-sm mb-2">Membership invitations will be sent after student record is created</div>
        </div>
        <div class="mb-2 flex gap-2">
            <button [disabled]="!hasEmail" [matTooltip]="hasEmail ? '' : 'Student must have a valid email to send a membership invitation'" *ngIf="selectedMembershipIds.length === 0" (click)="addMembership()" type="button" class="flex rounded-lg border px-3.5 py-2 border-gray-300 disabled:bg-gray-200 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <plus-small-outline-icon svgClass="heroicon-sw-1.5" [size]="24"></plus-small-outline-icon>
                Add membership plan
            </button>
            <button (click)="copyText()" type="button" [class.hover:bg-primary-600]="copyingText" [class.bg-primary-600]="copyingText" [class.text-white]="copyingText" class="flex rounded-lg border px-3.5 py-2 border-gray-300 hover:bg-gray-50 focus:ring focus:ring-gray-100 focus:outline-0">
                <span *ngIf="!copyingText">Student portal</span>
                <span *ngIf="copyingText">URL copied</span>
                <link-outline-icon *ngIf="!copyingText" svgClass="ml-2 heroicon-sw-1.5" [size]="20"></link-outline-icon>
            </button>
        </div>
        <div *ngIf="selectedMembershipIds.length > 0" class="my-4 border overflow-y-hidden rounded-lg">
            <table class="w-full overflow-scroll">
                <thead class="bg-gray-50 text-gray-500">
                    <tr class="text-left">
                        <th class="pl-6 py-3 w-1/4 font-medium">
                            Membership
                        </th>
                        <th class="pl-6 py-3 w-1/4 font-medium">
                            Start Date
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Recurrence
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Cost (Credit Card)
                        </th>
                        <th class="pl-6 py-3 font-medium">
                            Cost (Direct Debit)
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="[&>*:not(:first-child)]:border-t">
                    <tr class="hover:bg-gray-50" *ngFor="let membership of selectedMemberships">
                        <td class="px-6 py-4 cursor-pointer">
                            <span class="font-medium">{{membership.name}}</span>
                        </td>
                        <td class="px-6 py-4">
                            {{getStartDate(membership.id)|date:'dd MMM yyyy'}}
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getPaymentFrequency(membership.versions[0].paymentFrequency)}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getTotalCharged(membership.versions[0].paymentAmount, membership.versions[0].serviceAmountCard, membership.versions[0].chargeFees) | currency}}</span>
                        </td>
                        <td class="px-6 py-4">
                            <span class="font-medium">{{getTotalCharged(membership.versions[0].paymentAmount, membership.versions[0].serviceAmountDebit, membership.versions[0].chargeFees) | currency}}</span>
                        </td>
                        <td>
                            <div class="flex justify-end">
                                <trash-outline-icon (click)="removeMembership(membership.id)" svgClass="w-6 h-5 mr-7 cursor-pointer"></trash-outline-icon>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        }
        <app-save-footer *ngIf="this.studentCreateForm.dirty" [buttonText]="editing ? 'Update student' : 'Create student'" [error]="submitted && !studentCreateForm.valid" [loading]="submitting"></app-save-footer>
    </div>
</form>