@if (loading) {
    <fc-loader/>
}
@else {
    <div class="pt-3">
        <fc-timeline
            [timelineData]="history"
            (actionTriggered)="handleViewDetails($event)"
        ></fc-timeline>
    </div>
}